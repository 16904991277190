@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

body {
	color: $deep-green;
	font-family: $base-font-family;
	@include font-size($base-font-size);
	line-height: $base-line-height;
	-webkit-text-size-adjust: none;
}

span {
	&.caption {
		font-size: 1.4rem;
		line-height: 1.6rem;
		color: $grey;
		font-weight: normal;

		&.bold {
			color: $deep-green;
		}
	}
}

p,
label,
a {
	font-size: 1.6rem;
	color: $deep-green;
	line-height: 2rem;
	font-weight: $normal-base;
	max-width: 600px;

	&.full {
		max-width: 100vw;
	}

	&.error {
		color: $red;
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	&.invalid {
		color: $red;
	}

	&.valid {
		color: $light-green;
	}

	&.caption {
		font-size: 1.4rem;
		line-height: 1.6rem;
		color: $grey;

		&.bold {
			color: $deep-green;
		}
	}

	&.light {
		color: $grey;
	}

	&.bright {
		color: $white;
	}

	a {
		color: $deep-green;
		text-decoration: underline;
	}

	&.highlight {
		color: $purple;
		font-size: 1.8rem;
		font-weight: 600;
	}
}

.link {
	text-decoration: underline !important;

	&:hover {
		cursor: pointer !important;
	}
}

strong {
	font-weight: 700;
}

.capitalise {
	text-transform: capitalize;
}

a {
	text-decoration: none;

	&.coloured {
		color: inherit;

		&:visited {
			color: inherit;
		}
	}

	&:hover {
		cursor: pointer;
		text-decoration: underline;
		span {
			&.underline {
				text-decoration: underline;
			}
		}

		&:visited {
			color: inherit;
		}

		&.underline {
			text-decoration: underline;
		}

		&.noUnderline {
			text-decoration: none !important;
		}
	}
}

ul {
	list-style-position: outside;
	margin-left: 20px;
	li {
		font-size: 1.6rem;
		line-height: 2.8rem;
		margin-bottom: 10px;

		p {
			margin-bottom: 0;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $deep-green;
	font-weight: $bold-base;

	&.bright {
		color: $white;
	}
}

h1,
.h1 {
	font-size: 2.6rem;
	line-height: 3.3rem;
	font-family: $headline-font-family;

	@include desktop() {
		font-size: 3.8rem;
		line-height: 4.4rem;
	}
}

h2,
.h2 {
	font-size: 2.2rem;
	line-height: 2.8rem;
	font-family: $headline-font-family;

	@include desktop() {
		&:not([class*="mobileSize"]) {
			font-size: 3rem;
			line-height: 3.6rem;
		}
	}
}

h3,
.h3 {
	font-size: 2rem;
	line-height: 2.6rem;
	font-family: $base-font-family;
	font-weight: $bold-base;

	@include desktop() {
		&:not([class*="mobileSize"]) {
			font-size: 2.6rem;
			line-height: 3.4rem;
		}
	}
}

h4,
.h4 {
	font-size: 1.8rem;
	line-height: 2rem;
	font-family: $base-font-family;
	font-weight: $bold-base;

	@include desktop() {
		&:not([class*="mobileSize"]) {
			font-size: 2rem;
			line-height: 2.4rem;
		}
	}
}

h5,
.h5,
h6,
.h6 {
	font-size: 1.8rem;
	line-height: 2rem;
	font-family: $base-font-family;
	font-weight: $bold-base;
}

.paraLarge,
.paraLarge p,
.paraLarge ul,
.paraLarge li,
.paraLarge a {
	font-size: 1.8rem;
	line-height: 2.6rem;

	@include desktop() {
		font-size: 2rem;
		line-height: 2.8rem;
	}

	&.caption {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}
}

.paraMed,
.paraMed p,
.paraMed ul,
.paraMed li {
	font-size: 1.8rem;
	line-height: 2.6rem;

	@include desktop() {
		font-size: 1.8rem;
		line-height: 2.6rem;
	}

	&.caption {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}
}

.deleted {
	color: $grey;
}
